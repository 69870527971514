import React, { useEffect, useCallback } from 'react';
import { useFrame, useThree } from '@react-three/fiber';
import * as THREE from 'three';

const Camera: React.FC<{
  cameraPosition: THREE.Vector3;
  setCameraPosition: React.Dispatch<React.SetStateAction<THREE.Vector3>>;
  joystickData: THREE.Vector2;
  isMobileDevice: boolean;
  scene: THREE.Group | null;
}> = ({
  cameraPosition,
  setCameraPosition,
  joystickData,
  isMobileDevice,
  scene,
}) => {
  const { camera, gl } = useThree();
  const raycaster = new THREE.Raycaster();
  const direction = new THREE.Vector3();
  const MOVE_SPEED = 0.05; // 상수로 이동 속도 정의
  useFrame((state) => {
    if (joystickData.x !== 0 || joystickData.y !== 0) {
      const forwardVector = new THREE.Vector3(0, 0, -1)
        .applyQuaternion(state.camera.quaternion)
        .normalize();
      const forwardMove = forwardVector.multiplyScalar(
        joystickData.y * MOVE_SPEED
      );

      const rightVector = new THREE.Vector3(1, 0, 0)
        .applyQuaternion(state.camera.quaternion)
        .normalize();
      const strafeMove = rightVector.multiplyScalar(
        joystickData.x * MOVE_SPEED
      );

      const moveVector = forwardMove.add(strafeMove);

      const newCameraPosition = cameraPosition.clone().add(moveVector);
      newCameraPosition.y = cameraPosition.y;

      if (checkCollisionWithRaycasting(newCameraPosition)) {
        setCameraPosition(newCameraPosition);
        state.camera.position.copy(newCameraPosition);
      }
    }
  });

  const checkCollisionWithRaycasting = useCallback(
    (newCameraPosition: THREE.Vector3) => {
      if (!scene) return true;

      direction.subVectors(newCameraPosition, cameraPosition).normalize();
      raycaster.set(cameraPosition, direction);

      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const distance = intersects[0].distance;

        if (distance < direction.length()) {
          return false;
        }
      }

      return true;
    },
    [scene, cameraPosition]
  );

  const getClickedPosition = useCallback(
    (event: MouseEvent) => {
      if (!scene) return;

      const mouse = new THREE.Vector2();
      mouse.x = (event.clientX / window.innerWidth) * 2 - 1;
      mouse.y = -(event.clientY / window.innerHeight) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);

      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const intersect = intersects[0];
        console.log('클릭된 위치:', intersect.point);
      }
    },
    [scene, camera]
  );

  useEffect(() => {
    const handleClick = (event: MouseEvent) => getClickedPosition(event);
    gl.domElement.addEventListener('click', handleClick);

    return () => {
      gl.domElement.removeEventListener('click', handleClick);
    };
  }, [gl.domElement, getClickedPosition]);

  return null;
};

export default Camera;
