import * as THREE from 'three';
import { Html } from '@react-three/drei';
import { GalleryExhibitInfo } from '../../interfaces/GalleryExhibitInfo';
const Description = ({
  galleryExhibitInfo,
}: {
  galleryExhibitInfo: GalleryExhibitInfo;
}) => {
  return (
    <Html
      transform
      position={galleryExhibitInfo.position}
      quaternion={new THREE.Quaternion(...galleryExhibitInfo.rotation)}
    >
      <div
        style={{
          textAlign: 'left',
          maxWidth: '250px',
          padding: '10px',
          backgroundColor: '#f6f6f6',
          borderRadius: '5px',
          color: 'black',
        }}
      >
        <div className='d-flex justify-content-between'>
          <h5>{galleryExhibitInfo?.title}</h5>
          <div className='small-font'>{galleryExhibitInfo?.artist}</div>
        </div>

        <p className='small-font'>{galleryExhibitInfo?.description}</p>
        <p className='d-flex justify-content-end small-font'>
          {galleryExhibitInfo?.tags ?? ''}
        </p>
        <div className='d-flex'>
          <audio controls>
            <source
              src={galleryExhibitInfo?.audio_url ?? ''}
              type='audio/mp3'
            />
            Your browser does not support the audio element.
          </audio>
        </div>
      </div>
    </Html>
  );
};

export default Description;
