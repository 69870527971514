import React, { useEffect, useMemo, useRef } from 'react';
import { useFrame } from '@react-three/fiber';
import { useSearchParams } from 'react-router-dom';
import * as THREE from 'three';
import { easing } from 'maath';
import Frame from './Frame';
import { GalleryExhibitInfo } from '../../interfaces/GalleryExhibitInfo';

interface FramesProps {
  exhibitInfo: GalleryExhibitInfo[];
  setSelectedExhibit: React.Dispatch<
    React.SetStateAction<GalleryExhibitInfo | null>
  >;
  cameraPosition: THREE.Vector3;
  cameraQuaternion: THREE.Quaternion;
}

const GOLDENRATIO = 1.61803398875;

const cameraZoomOnSelect = 1.5;
const cameraHorizontalMove = (GOLDENRATIO * 1.2) / 2;

const Frames: React.FC<FramesProps> = ({
  exhibitInfo,
  setSelectedExhibit,
  cameraPosition,
  cameraQuaternion,
}) => {
  const ref = useRef<THREE.Group>(null);
  const clicked = useRef<THREE.Object3D | null>(null);

  const [searchParams, setSearchParams] = useSearchParams();

  const p = useMemo(() => new THREE.Vector3(), []);
  const q = useMemo(() => new THREE.Quaternion(), []);

  useEffect(() => {
    const exhibitId = searchParams.get('exhibit');
    clicked.current = ref.current?.getObjectByName(exhibitId || '') ?? null;
    if (clicked.current && exhibitId) {
      clicked.current.parent!.updateWorldMatrix(true, true);
      clicked.current.parent!.localToWorld(
        p.set(cameraHorizontalMove, 0, cameraZoomOnSelect)
      );
      clicked.current.parent!.getWorldQuaternion(q);

      const worldPos = new THREE.Vector3();
      const worldQuat = new THREE.Quaternion();
      clicked.current.parent!.localToWorld(worldPos.set(5, 0, -13));
      clicked.current.parent!.getWorldQuaternion(worldQuat);

      exhibitInfo.find((element) => {
        if (exhibitId === element.id.toString()) {
          setSelectedExhibit({
            id: element.id,
            position: [worldPos.x, worldPos.y, worldPos.z],
            rotation: [worldQuat.x, worldQuat.y, worldQuat.z, worldQuat.w],
            title: element.title,
            description: element.description ?? '',
            tags: element.tags ?? '',
            artist: element.artist ?? '',
            image_url: element.image_url,
            audio_url: element.audio_url,
          });
        }
      });
    } else {
      p.set(cameraPosition.x, cameraPosition.y, cameraPosition.z);
      q.set(
        cameraQuaternion.x,
        cameraQuaternion.y,
        cameraQuaternion.z,
        cameraQuaternion.w
      );
    }
  }, [searchParams, cameraPosition, cameraQuaternion, p, q]);

  useFrame((state, dt) => {
    easing.damp3(state.camera.position, p, 0.4, dt);
    easing.dampQ(state.camera.quaternion, q, 0.4, dt);
  });

  return (
    <group
      ref={ref}
      onClick={(e) => {
        e.stopPropagation();
        const newSearchParams = new URLSearchParams(searchParams);
        if (clicked.current === e.object) {
          newSearchParams.delete('exhibit');
          setSelectedExhibit(null);
        } else {
          const worldPos = new THREE.Vector3();
          const worldQuat = new THREE.Quaternion();
          e.object.parent!.localToWorld(worldPos.set(5, 0, -13));
          e.object.parent!.getWorldQuaternion(worldQuat);

          newSearchParams.set('exhibit', e.object.name);
          exhibitInfo.find((element) => {
            if (e.object.name === element.id.toString()) {
              setSelectedExhibit({
                id: element.id,
                position: [worldPos.x, worldPos.y, worldPos.z],
                rotation: [worldQuat.x, worldQuat.y, worldQuat.z, worldQuat.w],
                title: element.title,
                description: element.description ?? '',
                tags: element.tags ?? '',
                artist: element.artist ?? '',
                image_url: element.image_url,
                audio_url: element.audio_url,
              });
            }
          });
        }
        setSearchParams(newSearchParams);
      }}
      onPointerMissed={() => {
        const newSearchParams = new URLSearchParams(searchParams);
        if (newSearchParams.has('exhibit')) {
          newSearchParams.delete('exhibit');
          setSearchParams(newSearchParams);
        }
      }}
    >
      {exhibitInfo.map((props) => (
        <Frame key={props.image_url} {...props} />
      ))}
    </group>
  );
};

export default Frames;
