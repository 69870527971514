import React, { useRef, useState, useLayoutEffect } from 'react';
import { useFrame } from '@react-three/fiber';
import { useRoute } from 'wouter';
import {
  useTexture,
  MeshReflectorMaterial,
  useCursor,
} from '@react-three/drei';
import * as THREE from 'three';
import { easing } from 'maath';

interface FrameProps {
  position: [number, number, number];
  rotation: [number, number, number, number];
  id: number; // id를 string으로 변경
  image_url: string | null;
}

const Frame: React.FC<FrameProps> = ({ position, rotation, id, image_url }) => {
  const image = useRef<THREE.Mesh>(null);
  const frame = useRef<THREE.Mesh>(null);
  const [, params] = useRoute('/item/:id');
  const [hovered, setHovered] = useState(false);
  const [imageScale, setImageScale] = useState([1, 1]);

  const isActive = params?.id === id.toString();
  useCursor(hovered);

  const texture = useTexture(
    image_url ?? 'https://creaseum.com/storage/artworks/default.png'
  );

  useFrame((state, dt) => {
    easing.damp3(
      image.current!.scale,
      [
        0.85 * (!isActive && hovered ? 0.85 : 1) * imageScale[0],
        0.9 * (!isActive && hovered ? 0.905 : 1) * imageScale[1],
        1,
      ],
      0.1,
      dt
    );

    if (
      frame.current &&
      frame.current.material instanceof THREE.MeshStandardMaterial
    ) {
      easing.dampC(
        frame.current.material.color,
        hovered ? '#fad2ce' : 'white',
        0.1,
        dt
      );
    }
  });

  return (
    <group
      position={position}
      rotation={[rotation[0], rotation[1], rotation[2]]}
    >
      <mesh
        name={id.toString()}
        onPointerOver={(e) => (e.stopPropagation(), setHovered(true))}
        onPointerOut={() => setHovered(false)}
        scale={[1, 1, 0.05]}
        position={[1 / 2, 0, 0]}
      >
        <boxGeometry />
        <MeshReflectorMaterial
          color='#A9A9A9' // 은색
          mirror={0.5}
          metalness={0.8} // 금속 느낌을 주기 위한 값
          roughness={0.5} // 반사 효과를 강조하기 위한 값
        />
        <mesh
          ref={frame}
          raycast={() => null}
          scale={[0.9, 0.93, 0.9]}
          position={[0, 0, 0.2]}
        >
          <boxGeometry />
          <meshBasicMaterial toneMapped={false} fog={false} />
        </mesh>
        <mesh raycast={() => null} ref={image} position={[0, 0, 0.7]}>
          <planeGeometry args={[1, 1]} />
          <meshBasicMaterial map={texture} />
        </mesh>
      </mesh>
    </group>
  );
};

export default Frame;
